import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { useLoggedIn } from 'store/session';
import { INDEX } from 'utils/routes';
import Suspense from '../Suspense';

const PrivateRoute = ({ component: Component }) => {
  const isLoggedIn = useLoggedIn();

  return isLoggedIn ? (
    <Suspense>
      <Component />
    </Suspense>
  ) : (
    <Navigate to={INDEX} replace />
  );
};

PrivateRoute.propTypes = { component: PropTypes.elementType.isRequired };

export default PrivateRoute;
