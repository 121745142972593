export const FEATURE_NAME = 'analytics';

export const LABEL = 'label';
export const VALUE = 'value';
export const UNIT = 'unit';
export const CHANGE = 'change';
export const START = 'start';
export const END = 'end';
export const PERIOD = 'period';
export const PREV_VALUE = 'prevValue';
export const PREV_START = 'prevStart';
export const PREV_END = 'prevEnd';
