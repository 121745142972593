export const extractYupErrors = (yupErrors) =>
  (yupErrors?.inner || []).reduce((acc, { path, message }) => {
    acc[path] = message;

    return acc;
  }, {});

const formatDate = new Intl.DateTimeFormat('de', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});
const formatTime = new Intl.DateTimeFormat('de', {
  hour: '2-digit',
  minute: '2-digit',
});
const formatDateWithTime = new Intl.DateTimeFormat('de', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});
export const parseDate = (date) => (date ? formatDate.format(new Date(date)) : '');
export const parseTime = (date) => (date ? formatTime.format(new Date(date)) : '');
export const parseDateWithTime = (date) => (date ? formatDateWithTime.format(new Date(date)) : '');
