import { call, takeLeading } from 'redux-saga/effects';

import { withAlert } from 'store/alerts';
import api from 'api';

import { FETCH_ANALYTICS } from './types';

function* fetchAnalytics({ payload }) {
  return { success: yield call(api.get, '/analytics') };
}

export default function* watchAnalytics() {
  yield takeLeading(FETCH_ANALYTICS, withAlert(fetchAnalytics));
}
