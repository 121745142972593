import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import Btn from './styles';

const Button = ({ type, disabled, isActive, color, isLoading, className, children, ...rest }) => (
  <Btn type={type} disabled={disabled || isLoading} $isActive={isActive} $color={color} className={className} {...rest}>
    {isLoading ? <Spinner size={40} /> : children}
  </Btn>
);

Button.defaultProps = {
  type: 'button',
  disabled: false,
  isActive: false,
  color: 'default',
  isLoading: false,
  className: null,
};
Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'normal', 'success', 'warning']),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
