import styled from 'styled-components';
import { motion } from 'framer-motion';

import Btn from 'components/Button';

export const Container = styled(motion.form).attrs(() => ({
  animate: { y: '0%' },
  initial: { y: '-200%' },
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  inset: 95px 20px auto auto;
  width: 220px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  padding: 10px;
  z-index: 100;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const inputStyle = ({ $error }) => `
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid;
  border-radius: var(--border-radius8);
  border-color: ${($error && '#ff4124') || 'var(--color-grey97)'};
  font-size: var(--font-size12);
  font-weight: var(--font-weight400);
  color: ${$error ? 'var(--color-orangeDC)' : 'var(--color-grey6A)'};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  &::placeholder {
    color: var(--color-grey97);
  }
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;

export const Input = styled.input`
  ${inputStyle}
  height: 27px;
  padding: 0 10px;
`;

export const Textarea = styled.textarea`
  ${inputStyle}
  min-height: 100px;
  padding: 5px 10px;
  resize: none;
`;

export const Button = styled(Btn)`
  height: 22px;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.75);
`;
