import React from 'react';
import PropTypes from 'prop-types';

import Msg from './styles';

const Message = ({ type, isSmall, textAlign, children, className }) => (
  <Msg $type={type} $isSmall={isSmall} $textAlign={textAlign} className={className}>
    {children}
  </Msg>
);

Message.defaultProps = {
  type: '',
  isSmall: false,
  textAlign: 'left',
  className: null,
};

Message.propTypes = {
  type: PropTypes.oneOf(['error', 'success']),
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  children: PropTypes.node.isRequired,
};

export default Message;
