import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';

import { getAuth, getLoggedIn, getUser, getUserProp, getAuth2FA, getHas2FA, get2FALink } from './selectors';
import * as actions from './actions';

export const useSessionActions = wrapActions({ ...actions });

export const useAuth = wrapSelector(getAuth);
export const useAuth2FA = wrapSelector(getAuth2FA);
export const useHas2FA = wrapSelector(getHas2FA);
export const use2FALink = wrapSelector(get2FALink);
export const useLoggedIn = wrapSelector(getLoggedIn);
export const useUser = wrapMultiSelector(getUserProp, getUser);
