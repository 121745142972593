import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { pathOr, omit } from 'ramda';
import PropTypes from 'prop-types';

import { useLoggedIn, useUser, useSessionActions, useAlerts, EMAIL, MESSAGE, PAGE_URL } from 'store/session';
import { useAsyncState } from 'utils/hooks';
import { extractYupErrors } from 'utils';
import { ReactComponent as Icon } from 'assets/svg/close.svg';
import Message from 'components/Message';
import Spinner from 'components/Spinner';

import { validationSchema } from './utils';
import { Container, Wrapper, Textarea, Input, Button, SpinnerWrap } from './styles';

const Form = ({ onClose }) => {
  const { t } = useTranslation();
  const isLoggedIn = useLoggedIn();
  const email = useUser(EMAIL);
  const { pathname, search, hash } = useLocation();
  const [errors, setErrors] = useAsyncState({});
  const { sendBugReport } = useSessionActions();
  const { action, loading, success, error } = useAlerts(sendBugReport);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const values = await validationSchema(isLoggedIn).validate(
          {
            [EMAIL]: pathOr('', ['currentTarget', EMAIL, 'value'], e),
            [MESSAGE]: pathOr('', ['currentTarget', MESSAGE, 'value'], e),
            [PAGE_URL]: `${window.location.origin}${pathname}${search}${hash}`,
          },
          { abortEarly: false }
        );

        action(values);
      } catch (err) {
        setErrors(extractYupErrors(err));
      }
    },
    [action, hash, isLoggedIn, pathname, search, setErrors]
  );

  const onChange = useCallback(({ target }) => setErrors(($) => ($[target.name] ? omit([target.name], $) : $)), [setErrors]);

  useEffect(() => {
    if (success) onClose();
  }, [onClose, success]);

  return (
    <Container onSubmit={onSubmit} onChange={onChange}>
      <button type="button" onClick={onClose} aria-label={t('Close')} title={t('Close')}>
        <Icon />
      </button>
      <Wrapper>
        <Input
          name={EMAIL}
          defaultValue={email || ''}
          placeholder={t('Your email address')}
          $error={errors[EMAIL]}
          disabled={isLoggedIn || loading}
        />
        {errors[EMAIL] && (
          <Message type="error" isSmall>
            {t(errors[EMAIL])}
          </Message>
        )}
      </Wrapper>
      <Wrapper>
        <Textarea
          name={MESSAGE}
          placeholder={t('Give us feedback on any problems you have encountered')}
          $error={errors[MESSAGE]}
          disabled={loading}
        />
        {errors[MESSAGE] && (
          <Message type="error" isSmall>
            {t(errors[MESSAGE])}
          </Message>
        )}
      </Wrapper>
      <Button type="submit" color="success" disabled={loading}>
        {t('Send')}
      </Button>
      {error && (
        <Message type="error" isSmall>
          {error}
        </Message>
      )}
      {loading && (
        <SpinnerWrap>
          <Spinner flex={1} />
        </SpinnerWrap>
      )}
    </Container>
  );
};

Form.propTypes = { onClose: PropTypes.func.isRequired };

export default Form;
