import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import { useLoggedIn, useAuth, useSessionActions, useAlerts } from 'store/session';

const InitApp = ({ children }) => {
  const { restore, logout } = useSessionActions();
  const { id, action, loading, error } = useAlerts(restore);
  const isAuth = useAuth();
  const isLoggedIn = useLoggedIn();
  const shouldRestore = isAuth && !isLoggedIn;

  useEffect(() => {
    if (shouldRestore) action();
  }, [action, shouldRestore]);

  useEffect(() => {
    if (error) logout();
  }, [error, logout]);

  if (shouldRestore && (!id || loading)) return <Spinner flex={1} />;

  return children;
};

InitApp.propTypes = { children: PropTypes.node.isRequired };

export default InitApp;
