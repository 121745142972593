/* eslint-disable import/no-dynamic-require, no-param-reassign */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { FILE_NAMES, LANGUAGES, DEFAULT_LANGUAGE } from './consts';

const resources = LANGUAGES.reduce((collection, lng) => {
  collection[lng] = FILE_NAMES.reduce((acc, key) => {
    acc[key] = require(`./${lng}/${key}.json`);

    return acc;
  }, {});

  return collection;
}, {});

i18n.use(initReactI18next).init({
  resources,
  defaultNS: FILE_NAMES[0],
  lng: DEFAULT_LANGUAGE,
  keySeparator: false,
  nsSeparator: ':',
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.REACT_APP_TYPE !== 'production',
});

export default i18n;
