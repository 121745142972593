export const DEFAULT_ERROR = 'Oops, an error has occurred. Help us to improve your user experience and send us a bug report.';

export const TOKEN = 'token';
export const TOKEN_2FA = 'token2FA';
export const ID = '_id';
export const NAME = 'name';
export const CREATED = 'createdAt';

export const EXPAND = 'expand[]';
export const FIELDS = 'fields[]';

export const SORT = 'sort';
export const SORT_BY = 'sortBy';
export const SORT_DIR = 'sortDir';
export const ASC = 'asc';
export const DESC = 'desc';

export const PAGINATION = 'pagination';
export const CURSOR = 'start_after';
export const NEXT = 'startAfter';
export const LIMIT = 'limit';
export const COUNT = 'count';
export const HAS_MORE = 'hasMore';
