import { path } from 'ramda';

import { ID } from 'api/consts';
import { FEATURE_NAME as $, AUTH, LOGGED_IN, USER, AUTH_2F, HAS_2F, LINK_2FA } from './consts';

export const getAuth = path([$, AUTH]);
export const getLoggedIn = path([$, LOGGED_IN]);
export const getUser = path([$, USER]);
export const getUserProp = (name) => path([$, USER, name]);
export const getUserId = path([$, USER, ID]);
export const getAuth2FA = path([$, AUTH_2F]);
export const getHas2FA = path([$, HAS_2F]);
export const get2FALink = path([$, LINK_2FA]);
