import styled from 'styled-components';
import { repeat } from 'ramda';

const types = {
  default: 0,
  normal: 1,
  success: 2,
  warning: 3,
};

const getBackground = (type, action) => {
  const actions = {
    normal: ['var(--color-greyEC)', 'var(--color-blue4A)', 'var(--color-green65)', 'var(--color-orangeF5)'],
    hover: ['var(--color-greyF8)', '#6ea6e7', '#83dfb4', '#f79253'],
    pressed: ['#bdbcbc', '#3b73b4', 'var(--color-green50)', '#c45f20'],
    disabled: ['#eeeeee', '#bdd7f4', '#c2efda', '#fcd1b6'],
    active: repeat('var(--color-white)', 5),
  };

  return actions[action][types[type]] || 'var(--color-white)';
};

const getColor = (type) => ['var(--color-grey97)', '#003b68', '#005e18', '#6f2a08'][types[type]];

export default styled.button`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: var(--border-radius8);
  background: ${({ $color }) => getBackground($color, 'normal')};
  color: ${({ $color }) => getColor($color)};
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.9px;
  line-height: 1.15;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  @media (hover) {
    &:hover {
      background: ${({ $color }) => getBackground($color, 'hover')};
    }
  }
  &:active {
    background: ${({ $color }) => getBackground($color, 'pressed')};
  }
  &:disabled {
    background: ${({ $color }) => getBackground($color, 'disabled')};
    color: var(--color-grey97);
    cursor: not-allowed;
  }
  ${({ $color, $isActive }) =>
    $isActive
      ? `
      background: ${getBackground($color, 'active')} !important;
      border: 1px solid ${getBackground($color, 'pressed')};
    `
      : ''}
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;
