import React from 'react';
import PropTypes from 'prop-types';

import Suspense from '../Suspense';

const PublicRoute = ({ component: Component }) => (
  <Suspense>
    <Component />
  </Suspense>
);

PublicRoute.propTypes = { component: PropTypes.elementType.isRequired };

export default PublicRoute;
