import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  align-items: center;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid var(--color-grey97);
  padding: 0 15px;
`;

// export const IndexLink = styled(NavLink)`
//   display: block;
//   color: var(--color-black);
//   font-size: var(--font-size27);
//   font-weight: var(--font-weight700);
//   letter-spacing: 0.92px;
//   text-decoration: none;
//   text-transform: uppercase;
//   text-align: center;
//   cursor: pointer;
//   padding: 10px;
// `;

export const Link = styled(NavLink)`
  display: block;
  width: auto;
  max-width: 210px;
  background-color: var(--color-greyF8);
  border: 1px solid;
  border-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 38px;
  letter-spacing: 0.92px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 10px;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
  &.active {
    background-color: var(--color-white);
    border-color: var(--color-grey6A);
    cursor: not-allowed;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
`;

export const Button = styled.button`
  background-color: var(--color-greyF8);
  border: 1px solid;
  border-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 38px;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  padding: 0 10px;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
  &:active {
    background-color: var(--color-white);
    border-color: var(--color-grey6A);
    cursor: not-allowed;
  }
`;
