import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { INDEX } from 'utils/routes';
import GlobalStyles from 'theme/GlobalStyles';

import ROUTES from './utils';
import Header from './Header';
import InitApp from './InitApp';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Footer from './Footer';
import { Main } from './styles';

const Root = () => (
  <>
    <GlobalStyles />
    <InitApp>
      <Header>FRB</Header>
      <Main>
        <Routes>
          {ROUTES.map(({ path, access, component }) => (
            <Route
              key={path}
              index={path === INDEX}
              path={path}
              element={access ? <PrivateRoute component={component} /> : <PublicRoute component={component} />}
            />
          ))}
          <Route path="*" element={<Navigate to={INDEX} />} />
        </Routes>
      </Main>
      <Footer />
    </InitApp>
  </>
);

export default Root;
