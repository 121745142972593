import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import { Button } from './styles';

const BugReport = () => {
  const { t } = useTranslation();
  const [isOpened, setOpened] = useState(false);
  const onClick = useCallback(() => setOpened(($) => !$), []);
  const onClose = useCallback(() => setOpened(false), []);

  return (
    <>
      <Button type="button" onClick={onClick} $isActive={isOpened}>
        {t('Report problem')}
      </Button>
      {isOpened && <Form onClose={onClose} />}
    </>
  );
};

export default BugReport;
