import { object, string } from 'yup';

import { EMAIL, MESSAGE } from 'store/session';

export const validationSchema = (withoutEmail) =>
  object().shape({
    [EMAIL]: string().when((_, schema) =>
      withoutEmail ? schema : string().trim().email('Email address is invalid').required('Email address is missing')
    ),
    [MESSAGE]: string().required('Enter a message'),
  });
