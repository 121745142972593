import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_LIST = getName('UPDATE_LIST');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');

export const UPLOAD_TRANSFERS = getName('UPLOAD_TRANSFERS');
export const FETCH_TRANSFERS = getName('FETCH_TRANSFERS');
