export const FEATURE_NAME = 'transfers';

export const LIST = 'list';
export const FILTERS = 'filters';

export const FILE = 'file';
export const ORIGINAL_NAME = 'originalname';
export const STATUS = 'status';
export const SUB_OWNER = '_owner';

export const STATUSES = {
  0: { name: 'Pending', color: '#0f7eff' },
  1: { name: 'Successful', color: '#009838' },
  2: { name: 'Failed', color: '#ff0000' },
};
