import styled, { keyframes } from 'styled-components';

const ring = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Container = styled.div`
  flex: ${({ $flex }) => ($flex === 1 ? '1 1 auto' : '0 0 auto')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: ${({ $size }) => $size + 10}px;
`;

export const Ring = styled.div`
  position: relative;
  & > div {
    animation: ${ring} 1s linear infinite;
  }
  & > div,
  & > div:after {
    position: absolute;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border: 3px solid #51cacc;
    border-top-color: transparent;
    border-radius: 50%;
  }
  & > div:after {
    transform: rotate(90deg);
  }
`;
