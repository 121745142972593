import { handleActions } from 'redux-actions';

import { UPDATE_LIST, UPDATE_SORT, UPDATE_PAGINATION } from './types';
import { LIST, FILTERS, SORT, SORT_BY, SORT_DIR, CREATED, DESC, PAGINATION, CURSOR, LIMIT, COUNT, HAS_MORE } from '.';

const init = {
  [LIST]: [],
  [FILTERS]: {
    [SORT]: {
      [SORT_BY]: CREATED,
      [SORT_DIR]: DESC,
    },
    [PAGINATION]: {
      [CURSOR]: 0,
      [LIMIT]: 10,
      [COUNT]: 0,
      [HAS_MORE]: false,
    },
  },
};

export default handleActions(
  {
    [UPDATE_LIST]: (state, { payload }) => ({ ...state, [LIST]: payload }),
    [UPDATE_SORT]: (state, { payload }) => ({
      ...state,
      [FILTERS]: {
        [SORT]: payload,
        [PAGINATION]: {
          ...state[FILTERS][PAGINATION],
          [CURSOR]: 0,
        },
      },
    }),
    [UPDATE_PAGINATION]: (state, { payload }) => ({
      ...state,
      [FILTERS]: {
        ...state[FILTERS],
        [PAGINATION]: {
          ...state[FILTERS][PAGINATION],
          ...payload,
        },
      },
    }),
  },
  init
);
