import { takeLatest, call, put, takeLeading, select } from 'redux-saga/effects';
import sha256 from 'crypto-js/sha256';
import { map } from 'ramda';

import { withAlert, applyCancelToken } from 'store/alerts';
import api from 'api';

import { authenticate, updateUser, update2FALink, authenticate2FA } from './actions';
import { getUserId } from './selectors';
import {
  LOGIN,
  RESTORE,
  FETCH_RESET_LINK,
  RESET_PASSWORD,
  FETCH_LINK_2FA,
  FETCH_2FA,
  VALIDATE_2FA,
  RESET_2FA,
  UPDATE_PASSWORD,
  SEND_BUG_REPORT,
} from './types';
import { ERROR, PASSWORD, CODE } from './consts';

const baseURL = process.env.REACT_APP_URL;

function* login({ payload, ...rest }) {
  const data = yield call(
    api.post,
    '/login',
    { ...payload, [PASSWORD]: sha256(payload[PASSWORD]).toString() },
    { baseURL, ...applyCancelToken(rest) }
  );

  if (!data[ERROR]) yield put(authenticate(data));

  return data;
}

function* restore(action) {
  const data = yield call(api.get, '/session', { baseURL, ...applyCancelToken(action) });

  yield put(updateUser(data));
}

function* fetchResetLink({ payload, ...rest }) {
  const data = yield call(api.post, '/reset', payload, { baseURL, ...applyCancelToken(rest) });

  return data;
}

function* resetPassword({ payload, ...rest }) {
  const data = yield call(
    api.post,
    '/setpassword',
    { ...payload, [PASSWORD]: sha256(payload[PASSWORD]).toString() },
    { baseURL, ...applyCancelToken(rest) }
  );

  return data;
}

function* fetch2FALink(action) {
  const data = yield call(api.post, '/auth/2fa/create', {}, { baseURL, ...applyCancelToken(action) });

  yield put(update2FALink(data));
}
function* fetch2FA({ payload, ...rest }) {
  const data = yield call(api.post, '/auth/2fa/authenticate', { [CODE]: payload }, { baseURL, ...applyCancelToken(rest) });

  if (!data[ERROR]) {
    yield put(authenticate2FA(data));
  }

  return data;
}
function* validate2FA({ payload, ...rest }) {
  return yield call(api.post, '/auth/2fa/validate', { [CODE]: payload }, { baseURL, ...applyCancelToken(rest) });
}
function* reset2FA(action) {
  const data = yield call(api.post, '/auth/2fa/delete', {}, { baseURL, ...applyCancelToken(action) });

  yield put(authenticate(data));
}

function* updatePassword({ payload }) {
  const id = yield select(getUserId);
  const data = yield call(
    api.post,
    `/users/${id}/password`,
    map(($) => sha256($).toString(), payload)
  );

  yield put(updateUser(data));
}

function* sendBugReport({ payload }) {
  yield call(api.post, '/issues', payload);
}

export default function* watchSession() {
  yield takeLatest(LOGIN, withAlert(login));
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeLatest(FETCH_RESET_LINK, withAlert(fetchResetLink));
  yield takeLatest(RESET_PASSWORD, withAlert(resetPassword));
  yield takeLatest(FETCH_LINK_2FA, withAlert(fetch2FALink));
  yield takeLatest(FETCH_2FA, withAlert(fetch2FA));
  yield takeLatest(VALIDATE_2FA, withAlert(validate2FA));
  yield takeLatest(RESET_2FA, withAlert(reset2FA));
  yield takeLeading(UPDATE_PASSWORD, withAlert(updatePassword));
  yield takeLeading(SEND_BUG_REPORT, withAlert(sendBugReport));
}
