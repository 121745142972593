import React from 'react';
import PropTypes from 'prop-types';

import { Container, Ring } from './styles';

const Spinner = ({ size, flex, className }) => (
  <Container $size={size} $flex={flex} className={className}>
    <Ring $size={size}>
      <div>
        <div />
      </div>
    </Ring>
  </Container>
);

Spinner.defaultProps = {
  size: 50,
  flex: 0,
  className: null,
};
Spinner.propTypes = {
  size: PropTypes.number,
  flex: PropTypes.number,
  className: PropTypes.string,
};

export default Spinner;
