import React, { useMemo } from 'react';

import { Container, Text, Link } from './styles';

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Container>
      <Text>
        {`© ${year} FRB Middleware is a technology made by`}{' '}
        <Link href="https://medignition.com" target="_blank" rel="noopener noreferrer">
          medignition
        </Link>
      </Text>
    </Container>
  );
};

export default Footer;
