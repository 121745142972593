export const FEATURE_NAME = 'session';

export const ROLES = 'roles';
export const AUTH = 'auth';
export const LOGGED_IN = 'loggedIn';
export const USER = 'user';
export const AUTH_2F = 'auth2F';
export const HAS_2F = 'twoFactorEnabled';
export const LINK_2FA = 'link2FA';

export const EMAIL = 'email';
export const PASSWORD = 'password';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const ERROR = 'error';
export const CODE = 'code';
export const URL_2FA = 'url';
export const CURRENT_PSD = 'currentPassword';
export const NEXT_PSD = 'nextPassword';
export const MESSAGE = 'message';
export const PAGE_URL = 'url';
