import axiosDef from 'axios';

import { TOKEN, TOKEN_2FA, DEFAULT_ERROR } from './consts';

export const axios = axiosDef.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_TOKEN_APP,
    AUTHORIZATION: `Bearer ${sessionStorage[TOKEN_2FA] || localStorage[TOKEN] || process.env.REACT_APP_DEFAULT_TOKEN}`,
  },
  timeout: 60000,
  validateStatus: (status) => (status >= 200 && status < 300) || status === 401 || status === 403,
});

export const updateToken = (token, token2FA) => {
  if (token) {
    localStorage.setItem(TOKEN, token);
    sessionStorage.removeItem(TOKEN_2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${token}`,
    };
  }
  if (token2FA) {
    sessionStorage.setItem(TOKEN_2FA, token2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${token2FA}`,
    };
  }
  if (!(token || token2FA)) {
    localStorage.removeItem(TOKEN);
    sessionStorage.removeItem(TOKEN_2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${process.env.REACT_APP_DEFAULT_TOKEN}`,
    };
  }
};

const handleResponse = async ({ status, data }) => {
  if (status === 401) {
    updateToken();
    window.location.reload();
  }

  if (status >= 300) {
    const error = new Error(
      (status === 401 && 'Your authentication has failed. Please log in.') || (status === 403 && data?.error) || DEFAULT_ERROR
    );
    error.status = status;
    throw error;
  }

  return data;
};

const handler = async (promise, { url, body, config }) => {
  try {
    const args = body ? [url, body, config] : [url, config];

    return await handleResponse(await promise(...args));
  } catch (e) {
    if (e?.status) throw Error(e?.message);

    throw Error('Your request failed. Please try again or send us an error report.');
  }
};

const methods = {
  get: (url, config) => handler(axios.get, { url, config }),
  post: (url, body, config) => handler(axios.post, { url, body, config }),
  put: (url, body, config) => handler(axios.put, { url, body, config }),
  patch: (url, body, config) => handler(axios.patch, { url, body, config }),
  delete: (url, config) => handler(axios.delete, { url, config }),
};

export default methods;
