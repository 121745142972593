import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { props } from 'ramda';

import { wrapActions } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { SORT, PAGINATION, CURSOR, LIMIT } from 'api/consts';

import * as actions from './actions';
import { getList, getFilters } from './selectors';

export const useTransfersActions = wrapActions({ ...actions });

export const useTransfers = () => {
  const { fetchTransfers } = useTransfersActions();
  const { id, action, loading, error } = useAlerts(fetchTransfers);
  const list = useSelector(getList);
  const filters = useSelector(getFilters);
  const [sort, pagination] = props([SORT, PAGINATION], filters);
  const [cursor, limit] = props([CURSOR, LIMIT], pagination);

  useEffect(() => {
    action({ ...sort, [CURSOR]: cursor, [LIMIT]: limit });
  }, [action, cursor, limit, sort]);

  return { list, loading: !id || loading, filters, error };
};
