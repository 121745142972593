import { all, call, takeLeading, throttle, put, select } from 'redux-saga/effects';
import { pick, props } from 'ramda';

import { withAlert } from 'store/alerts';
import api, { axios } from 'api';

import { updateList, updatePagination } from './actions';
import { UPLOAD_TRANSFERS, FETCH_TRANSFERS } from './types';
import { getFilters } from './selectors';
import { COUNT, HAS_MORE, SORT, PAGINATION, CURSOR, LIMIT } from '.';

function* fetchTransfers({ payload }) {
  const data = yield call(api.get, '/transfers', { params: payload });

  yield put(updateList(data?.data));
  yield put(updatePagination(pick([COUNT, HAS_MORE], data)));
}

function* uploadTransfers({ payload }) {
  const data = yield all(
    payload.map((file) => {
      const body = new FormData();
      body.append('file', file, file.name);

      return call(api.post, '/transfers', body, {
        headers: { ...axios.defaults.headers, 'Content-Type': 'multipart/form-data' },
      });
    })
  );
  const filters = yield select(getFilters);
  const [sort, pagination] = props([SORT, PAGINATION], filters);
  const [cursor, limit] = props([CURSOR, LIMIT], pagination);

  yield fetchTransfers({ payload: { ...sort, [CURSOR]: cursor, [LIMIT]: limit } });

  return { success: data };
}

export default function* watchTransfers() {
  yield takeLeading(UPLOAD_TRANSFERS, withAlert(uploadTransfers));
  yield throttle(500, FETCH_TRANSFERS, withAlert(fetchTransfers));
}
