import styled from 'styled-components';

export const Container = styled.footer`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 25px;
  padding: 0 15px;
`;

export const Text = styled.p`
  font-size: var(--font-size14);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
`;

export const Link = styled.a`
  font-size: var(--font-size14);
  font-weight: var(--font-weight500);
  color: var(--color-blue5D);
  text-decoration: none;
`;
