import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_LOADING = getName('SET_LOADING');
export const SET_SUCCESS = getName('SET_SUCCESS');
export const SET_ERROR = getName('SET_ERROR');
export const SET_CANCEL = getName('SET_CANCEL');
