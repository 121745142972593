import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import { UPDATE_LIST, UPDATE_SORT, UPDATE_PAGINATION, UPLOAD_TRANSFERS, FETCH_TRANSFERS } from './types';

export const updateList = createAction(UPDATE_LIST);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);

export const uploadTransfers = createAlertAction(UPLOAD_TRANSFERS);
export const fetchTransfers = createAlertAction(FETCH_TRANSFERS);
