import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoggedIn, useUser, useSessionActions, FIRST_NAME, LAST_NAME } from 'store/session';
import { DASHBOARD, INDEX, PROFILE, TRANSFER } from 'utils/routes';

import BugReport from './BugReport';
import { Container, Wrapper, Link, Button } from './styles';

const Header = () => {
  const { t } = useTranslation();
  const isLoggedIn = useLoggedIn();
  const [firstName, lastName] = useUser([FIRST_NAME, LAST_NAME]);
  const name = useMemo(() => [firstName, lastName].filter(Boolean).join(' '), [firstName, lastName]);
  const { logout } = useSessionActions();
  const onClick = useCallback(() => logout(), [logout]);

  return (
    <Container>
      <div />
      {/* <IndexLink to={isLoggedIn ? DASHBOARD : INDEX}>FRB</IndexLink> */}
      <Wrapper>
        {isLoggedIn ? (
          <>
            <Link to={TRANSFER}>{t('Transfer')}</Link>
            <Link to={DASHBOARD}>{t('Dashboard')}</Link>
            <Link to={PROFILE}>{name}</Link>
            <Button type="button" onClick={onClick}>
              {t('Logout')}
            </Button>
          </>
        ) : (
          <Link to={INDEX}>{t('Login')}</Link>
        )}
        <BugReport />
      </Wrapper>
    </Container>
  );
};

export default Header;
