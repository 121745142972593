import { lazy } from 'react';

import { INDEX, RESET, SET_PASSWORD, DASHBOARD, TRANSFER, PROFILE } from 'utils/routes';

const ROUTES = [
  {
    path: INDEX,
    access: false,
    component: lazy(() => import('pages/Login')),
    meta: {},
  },
  {
    path: RESET,
    access: false,
    component: lazy(() => import('pages/Reset')),
    meta: {},
  },
  {
    path: SET_PASSWORD,
    access: false,
    component: lazy(() => import('pages/SetPassword')),
    meta: {},
  },
  {
    path: DASHBOARD,
    access: true,
    component: lazy(() => import('pages/Dashboard')),
    meta: {},
  },
  {
    path: TRANSFER,
    access: true,
    component: lazy(() => import('pages/Transfer')),
    meta: {},
  },
  {
    path: PROFILE,
    access: true,
    component: lazy(() => import('pages/Profile')),
    meta: {},
  },
];

export default ROUTES;
