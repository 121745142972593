import { handleActions } from 'redux-actions';
import { prop } from 'ramda';

import { updateToken } from 'api';
import { TOKEN, TOKEN_2FA } from 'api/consts';

import { AUTHENTICATE, UPDATE_USER, LOGOUT, UPDATE_USER_DATA, AUTHENTICATE_2FA, UPDATE_2FA_LINK } from './types';
import { AUTH, LOGGED_IN, USER, AUTH_2F, HAS_2F, LINK_2FA, URL_2FA } from './consts';

const getInit = () => ({
  [AUTH]: Boolean(prop(TOKEN, localStorage)),
  [AUTH_2F]: Boolean(prop(TOKEN_2FA, sessionStorage)),
  [HAS_2F]: false,
  [LOGGED_IN]: false,
  [USER]: {},
});

const handleUserData = (user = {}) => {
  const has2FA = prop(HAS_2F, user);
  const isLoggedIn = has2FA ? Boolean(prop(TOKEN_2FA, sessionStorage)) : Boolean(prop(TOKEN, localStorage));

  return {
    [HAS_2F]: has2FA,
    [LOGGED_IN]: isLoggedIn,
    [USER]: user,
  };
};

export default handleActions(
  {
    [AUTHENTICATE]: (state, { payload }) => {
      const token = prop(TOKEN, payload);
      localStorage.setItem(TOKEN, token);
      localStorage.removeItem(TOKEN_2FA);
      updateToken(token);

      return {
        ...state,
        [AUTH]: Boolean(token),
        [AUTH_2F]: null,
        ...handleUserData(payload),
      };
    },
    [AUTHENTICATE_2FA]: (state, { payload }) => {
      const token = prop('token', payload);
      sessionStorage.removeItem(TOKEN);
      sessionStorage.setItem(TOKEN_2FA, token);
      updateToken(null, token);

      return {
        ...state,
        [AUTH]: null,
        [AUTH_2F]: Boolean(token),
        ...handleUserData(payload),
      };
    },
    [UPDATE_USER]: (state, { payload }) => ({
      ...state,
      ...handleUserData(payload),
    }),
    [LOGOUT]: () => {
      localStorage.removeItem(TOKEN);
      sessionStorage.removeItem(TOKEN_2FA);
      updateToken();

      return getInit();
    },
    [UPDATE_2FA_LINK]: (state, { payload }) => ({
      ...state,
      [LINK_2FA]: prop(URL_2FA, payload),
    }),
    [UPDATE_USER_DATA]: (state, { payload }) => ({
      ...state,
      [USER]: payload,
    }),
  },
  getInit()
);
