import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

class SuspenseComponent extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (error && error.name === 'ChunkLoadError') {
      return { hasError: true };
    }

    return null;
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <h1>Suspense Error Page</h1> : <Suspense fallback={<Spinner flex={1} />}>{children}</Suspense>;
  }
}

SuspenseComponent.propTypes = { children: PropTypes.node.isRequired };

export default SuspenseComponent;
